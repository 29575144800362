import PropTypes from 'prop-types';
import PromotionSlider from 'components/content-components/Promotional/PromotionSlider';

const FeaturePromotion = ({
    columnSizes = [],
    features = [],
    heading,
    headingFontKeys = ['Secondary/32_100_-06', null, null, null, 'Secondary/40_100_-075'],
    headingType,
    id,
    imageSettings = {},
    tag,
    tagFontKeys = [],
    text,
}) => {
    if (features.length < 1) {
        return null;
    }

    return (
        <PromotionSlider
            $style={{ padding: '64px 0' }}
            columnSizes={columnSizes}
            heading={heading}
            headingFontKeys={headingFontKeys}
            headingType={headingType}
            id={id}
            imageSettings={imageSettings}
            items={features}
            slidesPerView={[1.4, null, 2.6, null, null, 2]}
            tag={tag}
            tagFontKeys={tagFontKeys}
            text={text}
            type="feature"
        />
    );
};

FeaturePromotion.propTypes = {
    columnSizes: PropTypes.array,
    features: PropTypes.array,
    heading: PropTypes.string,
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string,
    imageSettings: PropTypes.shape({
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
        sizes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    }).isRequired,
    tag: PropTypes.string,
    tagFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    text: PropTypes.string,
};

export default FeaturePromotion;
